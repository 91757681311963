"use client";

import React, { Suspense } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@mui/material";
import { FeedBackProvider } from "../providers/FeedBackProvider/FeedBackProvider";
import { ClientsFiltersProvider } from "@/providers/ClientsFiltersContext";
import theme from "./theme";
import { EarningsFiltersProvider } from "@/providers/EarningsFiltersContext";

function Providers({ children }: React.PropsWithChildren) {
  const [client] = React.useState(new QueryClient());

  return (
    <QueryClientProvider client={client}>
      <ThemeProvider theme={theme}>
        <ClientsFiltersProvider>
          <EarningsFiltersProvider>
            <FeedBackProvider>
              <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
            </FeedBackProvider>
          </EarningsFiltersProvider>
        </ClientsFiltersProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default Providers;
