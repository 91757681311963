import { ReactNode } from "react";
import withLayout from "../utils/withLayout";
import NotFound from "../pages/ErrorPages/NotFound";
import Forbidden from "../pages/ErrorPages/Forbidden";
import Home from "../pages";
import UsersPage from "../pages/users";
import ClientsPage from "../pages/clients";
import ParticipantProfilePage from "../pages/clients/profile";
import GoalsPage from "../pages/clients/profile/goals";
import rewardsConfigPage from "../pages/rewardsConfiguration";
import RewardsPage from "@/pages/clients/profile/rewards";
import DashboardPage from "@/pages/dashboard";
import JournalsPage from "@/pages/clients/profile/journals";

export interface RouteItem {
  path: string; // Path of the route
  element?: ReactNode; // Element to render for this route (optional for parent routes with children)
  requiresAuth?: boolean; // Whether authentication is required
  requiredActions?: string[]; // Required actions to access the route
  children?: RouteItem[]; // Nested routes (children)
}

//TODO: we should extract the known routes from the db so they are dynamic
export const routesConfig = [
  {
    path: "/",
    element: withLayout(Home)({}),
    requiresAuth: false,
    requiredActions: ["DASHBOARD_VIEW"],
  },
  {
    path: "/participants",
    element: withLayout(ClientsPage)({}),
    requiredActions: ["PARTICIPANTS_VIEW"],
    requiresAuth: true,
  },
  {
    path: "/participants/:participantId",
    element: withLayout(ParticipantProfilePage)({}),
    requiredActions: ["PARTICIPANTS_VIEW"],
    requiresAuth: true,
  },
  {
    path: "/participants/:participantId/goals",
    element: withLayout(GoalsPage)({}),
    requiredActions: ["PARTICIPANT_GOALS_VIEW"],
    requiresAuth: true,
  },
  {
    path: "/users",
    element: withLayout(UsersPage)({}),
    requiredActions: ["USERS_VIEW"],
    requiresAuth: true,
  },
  {
    path: "/rewardsConfiguration",
    element: withLayout(rewardsConfigPage)({}),
    requiredActions: ["REWARDS_CONFIGURATION_VIEW"],
    requiresAuth: true,
  },
  {
    path: "/dashboard",
    element: withLayout(DashboardPage)({}),
    requiredActions: ["DASHBOARD_VIEW"],
    requiresAuth: true,
  },
  {
    path: "/403",
    element: withLayout(Forbidden, true)({}),
    requiresAuth: false,
  },
  {
    path: "/404",
    element: withLayout(NotFound, true)({}),
    requiresAuth: false,
  },
  {
    path: "/participants/:participantId/rewards",
    element: withLayout(RewardsPage)({}),
    requiredActions: ["PARTICIPANT_REWARDS_VIEW"],
    requiresAuth: true,
  },
  {
    path: "/participants/:participantId/journals",
    element: withLayout(JournalsPage)({}),
    requiredActions: ["PARTICIPANT_JOURNALS_VIEW"],
    requiresAuth: true,
  },
];

export const isKnownRoute = (path: string): boolean => {
  const routePatterns = routesConfig.flatMap((route: RouteItem) => {
    const childPatterns =
      route.children
        ?.filter((child) => child.element)
        .map((child) => `${route.path.replace(/\/$/, "")}/${child.path}`) || [];

    return route.element ? [route.path, ...childPatterns] : childPatterns;
  });

  const regexPatterns = routePatterns.map(
    (routePattern) => new RegExp(`^${routePattern.replace(/:\w+/g, "\\w+")}$`)
  );

  return regexPatterns.some((regex) => regex.test(path));
};
